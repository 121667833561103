import crypto from 'crypto';
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useDebouncedCallback } from 'use-debounce';
import { updateURLParams, URLParam } from 'helpers/utils/updateURLParams';
import { TermFilterParams } from '../../../components/commercetools-ui/term-filter';

type Filter = {
  terms: {
    [key: string]: {
      params: TermFilterParams[];
      selected: Record<string, boolean>;
    };
  };
  termFilteringParams: URLParam[];
  priceFilteringParams: URLParam[];
  sizeFilteringParams: URLParam[];
  sortingParam: URLParam;
  shouldSubmit: boolean;
};

type FilterContextValue = {
  filter: Filter;
  debounceDelay: number;
  isUpdating: boolean;
  setFilter: Dispatch<SetStateAction<Filter>>;
  resetFilter: () => void;
  submitFilter: () => void;
  setDebounceDelay: Dispatch<SetStateAction<number>>;
};

type FilterHashes = {
  termFilteringParams?: string;
  priceFilteringParams?: string;
  sizeFilteringParams?: string;
  sortingParam?: string;
};

const initialState: Filter = {
  terms: {},
  termFilteringParams: [],
  priceFilteringParams: [],
  sizeFilteringParams: [],
  sortingParam: undefined,
  shouldSubmit: false,
};

const FilterContext = createContext<FilterContextValue>(undefined);

const useFilter = () => {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error('useFilter must be used within a FilterProvider');
  }

  return context;
};

const FilterProvider = (props) => {
  const router = useRouter();
  const [filter, setFilter] = useState(initialState);
  const [isUpdating, setIsUpdating] = useState(false);
  const [debounceDelay, setDebounceDelay] = useState(500);
  const lastFilter = useRef<FilterHashes>(undefined);
  const { termFilteringParams, priceFilteringParams, sizeFilteringParams, sortingParam, shouldSubmit } = filter;

  const getFilterHash = (params?: URLParam | URLParam[]): string => {
    if (!params || (Array.isArray(params) && params.length === 0)) {
      return '';
    }

    if (!Array.isArray(params)) {
      const key = params.key.replace(/\[terms\]\[\d+\]/, '');
      return `${key}=${params.value}`;
    }

    return crypto
      .createHash('md5')
      .update(
        params
          .map((param) => getFilterHash(param))
          .sort()
          .join('|'),
      )
      .digest('hex');
  };

  const updateLastFilter = () => {
    lastFilter.current = {
      sortingParam: getFilterHash(filter.sortingParam ?? []),
      priceFilteringParams: getFilterHash(filter.priceFilteringParams),
      sizeFilteringParams: getFilterHash(filter.sizeFilteringParams),
      termFilteringParams: getFilterHash(filter.termFilteringParams),
    };
  };

  const filteringParamsChanged = (): boolean => {
    if (lastFilter.current === undefined) {
      return false;
    }

    return (
      lastFilter.current.priceFilteringParams !== getFilterHash(filter.priceFilteringParams) ||
      lastFilter.current.sizeFilteringParams !== getFilterHash(filter.sizeFilteringParams) ||
      lastFilter.current.termFilteringParams !== getFilterHash(filter.termFilteringParams)
    );
  };

  const sortingParamsChanged = (): boolean => {
    return lastFilter.current.sortingParam !== getFilterHash(filter.sortingParam);
  };

  const resetFilter = () => {
    setFilter(initialState);
  };

  const submitFilter = () => {
    const params = [];
    setIsUpdating(true);

    if (priceFilteringParams) {
      params.push(...priceFilteringParams);
    }

    if (sortingParam) {
      params.push(sortingParam);
    }

    if (sizeFilteringParams) {
      params.push(...sizeFilteringParams);
    }

    if (termFilteringParams) {
      params.push(...termFilteringParams);
    }

    const currentUrl = updateURLParams(params);
    router.push(currentUrl, undefined, { scroll: false }).then(() => setIsUpdating(false));
  };

  const value: FilterContextValue = useMemo(
    () => ({
      filter,
      debounceDelay,
      isUpdating,
      setFilter,
      resetFilter,
      submitFilter,
      setDebounceDelay,
    }),
    [filter, debounceDelay, isUpdating],
  );

  const debouncedSubmit = useDebouncedCallback(() => {
    submitFilter();
  }, debounceDelay);

  useEffect(() => {
    if (filter.shouldSubmit && filteringParamsChanged()) {
      if (debounceDelay > 0) {
        debouncedSubmit();
      } else {
        submitFilter();
      }
    }
    updateLastFilter();
  }, [termFilteringParams, sizeFilteringParams, priceFilteringParams]);

  useEffect(() => {
    if (shouldSubmit && sortingParamsChanged()) {
      updateLastFilter();
      submitFilter();
    }
  }, [sortingParam]);

  return <FilterContext.Provider value={value} {...props} />;
};

export { FilterProvider, useFilter, initialState as initialFilterState };
